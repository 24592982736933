import { template as template_0361c1cba2194dc7a1ff275860b42761 } from "@ember/template-compiler";
const FKText = template_0361c1cba2194dc7a1ff275860b42761(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
