import { template as template_45b970e15f4e4ae6a65baa60c2cc3f94 } from "@ember/template-compiler";
const FKControlMenuContainer = template_45b970e15f4e4ae6a65baa60c2cc3f94(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
