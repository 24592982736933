import { template as template_998b96090aef46148477ba29c3f70b04 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_998b96090aef46148477ba29c3f70b04(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
